@media (max-width: 1920px) {
  .users-list > div {
    width: 1930px !important;
  }
  .users-list {
    overflow-x: scroll !important;
  }
}

@media (max-width: 1850px) {
  .users-list > div {
    width: 1887px !important;
  }
  .users-list {
    overflow-x: scroll !important;
  }
}

@media (min-width: 1850px) and (max-width: 2560px) {
  .table-mask-shadow {
    display: none !important;
  }
}

.users-list
  > div
  > div:nth-of-type(2)
  > div
  > div
  > div.MuiDataGrid-row
  > div.MuiDataGrid-cell:nth-of-type(7) {
  justify-content: flex-end;
  padding-right: 2.2%;
}

@media (max-width: 1440px) {
  .users-list > div {
    width: 1595px !important;
  }
  .users-list {
    overflow-x: scroll !important;
    overflow-y: unset !important;
  }

  /* first name */
  .users-list
    > div
    > div
    > .MuiDataGrid-columnHeadersInner
    > div
    > div.MuiDataGrid-columnHeader:nth-of-type(2) {
    width: 125px !important;
    max-width: 125px !important;
    min-width: 125px !important;
  }
  .users-list
    > div
    > div:nth-of-type(2)
    > div
    > div
    > div.MuiDataGrid-row
    > div.MuiDataGrid-cell:nth-of-type(2) {
    width: 125px !important;
    max-width: 125px !important;
    min-width: 125px !important;
  }
  /* last name */

  .users-list
    > div
    > div
    > .MuiDataGrid-columnHeadersInner
    > div
    > div.MuiDataGrid-columnHeader:nth-of-type(3) {
    width: 123px !important;
    max-width: 123px !important;
    min-width: 123px !important;
  }
  .users-list
    > div
    > div:nth-of-type(2)
    > div
    > div
    > div.MuiDataGrid-row
    > div.MuiDataGrid-cell:nth-of-type(3) {
    width: 123px !important;
    max-width: 123px !important;
    min-width: 123px !important;
  }
  /*  username */

  .users-list
    > div
    > div
    > .MuiDataGrid-columnHeadersInner
    > div
    > div.MuiDataGrid-columnHeader:nth-of-type(4) {
    width: 120px !important;
    max-width: 120px !important;
    min-width: 120px !important;
  }
  .users-list
    > div
    > div:nth-of-type(2)
    > div
    > div
    > div.MuiDataGrid-row
    > div.MuiDataGrid-cell:nth-of-type(4) {
    width: 120px !important;
    max-width: 120px !important;
    min-width: 120px !important;
  }
  /* org */

  .users-list
    > div
    > div
    > .MuiDataGrid-columnHeadersInner
    > div
    > div.MuiDataGrid-columnHeader:nth-of-type(5) {
    width: 200px !important;
    max-width: 200px !important;
    min-width: 200px !important;
  }
  .users-list
    > div
    > div:nth-of-type(2)
    > div
    > div
    > div.MuiDataGrid-row
    > div.MuiDataGrid-cell:nth-of-type(5) {
    width: 200px !important;
    max-width: 200px !important;
    min-width: 200px !important;
  }
  /* role */

  .users-list
    > div
    > div
    > .MuiDataGrid-columnHeadersInner
    > div
    > div.MuiDataGrid-columnHeader:nth-of-type(6) {
    width: 185px !important;
    max-width: 185px !important;
    min-width: 185px !important;
  }
  .users-list
    > div
    > div:nth-of-type(2)
    > div
    > div
    > div.MuiDataGrid-row
    > div.MuiDataGrid-cell:nth-of-type(6) {
    width: 185px !important;
    max-width: 185px !important;
    min-width: 185px !important;
  }
  /* activa mtters */

  .users-list
    > div
    > div
    > .MuiDataGrid-columnHeadersInner
    > div
    > div.MuiDataGrid-columnHeader:nth-of-type(7) {
    width: fit-content !important;
    max-width: fit-content !important;
    min-width: fit-content !important;
  }
  .users-list
    > div
    > div:nth-of-type(2)
    > div
    > div
    > div.MuiDataGrid-row
    > div.MuiDataGrid-cell:nth-of-type(7) {
    width: 148px !important;
    max-width: 148px !important;
    min-width: 148px !important;
  }
  /* email */

  .users-list
    > div
    > div
    > .MuiDataGrid-columnHeadersInner
    > div
    > div.MuiDataGrid-columnHeader:nth-of-type(8) {
    width: 165px !important;
    max-width: 165px !important;
    min-width: 165px !important;
  }

  .users-list
    > div
    > div:nth-of-type(2)
    > div
    > div
    > div.MuiDataGrid-row
    > div.MuiDataGrid-cell:nth-of-type(8) {
    width: 165px !important;
    max-width: 165px !important;
    min-width: 165px !important;
  }
  /* phone */

  .users-list
    > div
    > div
    > .MuiDataGrid-columnHeadersInner
    > div
    > div.MuiDataGrid-columnHeader:nth-of-type(9) {
    width: 150px !important;
    max-width: 150px !important;
    min-width: 150px !important;
  }

  .users-list
    > div
    > div:nth-of-type(2)
    > div
    > div
    > div.MuiDataGrid-row
    > div.MuiDataGrid-cell:nth-of-type(9) {
    width: 150px !important;
    max-width: 150px !important;
    min-width: 150px !important;
  }
  /* date */

  .users-list
    > div
    > div
    > .MuiDataGrid-columnHeadersInner
    > div
    > div.MuiDataGrid-columnHeader:nth-of-type(10) {
    width: 135px !important;
    max-width: 135px !important;
    min-width: 135px !important;
  }
  .users-list
    > div
    > div:nth-of-type(2)
    > div
    > div
    > div.MuiDataGrid-row
    > div.MuiDataGrid-cell:nth-of-type(10) {
    width: 135px !important;
    max-width: 135px !important;
    min-width: 135px !important;
  }
  /* status */

  .users-list
    > div
    > div
    > .MuiDataGrid-columnHeadersInner
    > div
    > div.MuiDataGrid-columnHeader:nth-of-type(11) {
    width: 190px !important;
    max-width: 190px !important;
    min-width: 190px !important;
  }
  .users-list
    > div
    > div:nth-of-type(2)
    > div
    > div
    > div.MuiDataGrid-row
    > div.MuiDataGrid-cell:nth-of-type(11) {
    width: 190px !important;
    max-width: 190px !important;
    min-width: 190px !important;
  }
}
