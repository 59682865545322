.lk-prejoin {
  background-color: white;
  flex-direction: column;
  gap: 32px;
  padding: 0px;
  width: 100%;
}

.lk-video-container {
  aspect-ratio: 16/9 !important;
}
