/* @media (max-width: 2560px) {
  .MuiDataGrid-columnHeadersInner
    > div
    > div.MuiDataGrid-columnHeader:nth-of-type(6) {
    width: 230px !important;
  }
  .MuiDataGrid-row > div.MuiDataGrid-cell:nth-of-type(6) {
    width: 230px !important;
  }
} */

@media (max-width: 1850px) {
  .task-list-dashboard > div {
    width: 1642px !important;
  }
  .task-list-dashboard {
    overflow-x: scroll !important;
  }
}

@media (min-width: 1850px) and (max-width: 2560px) {
  .table-mask-shadow {
    display: none !important;
  }
}
.task-list-dashboard
  > div
  > div:nth-of-type(2)
  > div
  > div
  > div.MuiDataGrid-row
  > div.MuiDataGrid-cell:nth-of-type(3) {
  white-space: pre-wrap !important;
}

@media (max-width: 1440px) {
  .table-mask-shadow {
    display: none !important;
  }

  .task-list-dashboard > div {
    width: 1113px !important;
  }

  .task-list-dashboard {
    overflow-x: scroll !important;
    overflow-y: unset !important;
  }

  /* title */
  .task-list-dashboard
    > div
    > div
    > .MuiDataGrid-columnHeadersInner
    > div
    > div.MuiDataGrid-columnHeader:nth-of-type(1) {
    width: 160px !important;
    max-width: 160px !important;
    min-width: 160px !important;
  }
  .task-list-dashboard
    > div
    > div:nth-of-type(2)
    > div
    > div
    > div.MuiDataGrid-row
    > div.MuiDataGrid-cell:nth-of-type(1) {
    width: 160px !important;
    max-width: 160px !important;
    min-width: 160px !important;
  }

  /* description */

  .task-list-dashboard
    > div
    > div
    > .MuiDataGrid-columnHeadersInner
    > div
    > div.MuiDataGrid-columnHeader:nth-of-type(2) {
    width: 290px !important;
    max-width: 290px !important;
    min-width: 290px !important;
  }
  .task-list-dashboard
    > div
    > div:nth-of-type(2)
    > div
    > div
    > div.MuiDataGrid-row
    > div.MuiDataGrid-cell:nth-of-type(2) {
    width: 290px !important;
    max-width: 290px !important;
    min-width: 290px !important;
  }

  /* status */

  .task-list-dashboard
    > div
    > div
    > .MuiDataGrid-columnHeadersInner
    > div
    > div.MuiDataGrid-columnHeader:nth-of-type(3) {
    width: 127px !important;
    max-width: 127px !important;
    min-width: 127px !important;
  }
  .task-list-dashboard
    > div
    > div:nth-of-type(2)
    > div
    > div
    > div.MuiDataGrid-row
    > div.MuiDataGrid-cell:nth-of-type(3) {
    width: 127px !important;
    max-width: 127px !important;
    min-width: 127px !important;
  }

  /* deadline */

  .task-list-dashboard
    > div
    > div
    > .MuiDataGrid-columnHeadersInner
    > div
    > div.MuiDataGrid-columnHeader:nth-of-type(4) {
    width: 123px !important;
    max-width: 123px !important;
    min-width: 123px !important;
  }
  .task-list-dashboard
    > div
    > div:nth-of-type(2)
    > div
    > div
    > div.MuiDataGrid-row
    > div.MuiDataGrid-cell:nth-of-type(4) {
    width: 123px !important;
    max-width: 123px !important;
    min-width: 123px !important;
  }

  /* first name */

  .task-list-dashboard
    > div
    > div
    > .MuiDataGrid-columnHeadersInner
    > div
    > div.MuiDataGrid-columnHeader:nth-of-type(5) {
    width: 137px !important;
    max-width: 137px !important;
    min-width: 137px !important;
  }
  .task-list-dashboard
    > div
    > div:nth-of-type(2)
    > div
    > div
    > div.MuiDataGrid-row
    > div.MuiDataGrid-cell:nth-of-type(5) {
    width: 137px !important;
    max-width: 137px !important;
    min-width: 137px !important;
  }

  /* last name */

  .task-list-dashboard
    > div
    > div
    > .MuiDataGrid-columnHeadersInner
    > div
    > div.MuiDataGrid-columnHeader:nth-of-type(6) {
    width: 134px !important;
    max-width: 134px !important;
    min-width: 134px !important;
  }
  .task-list-dashboard
    > div
    > div:nth-of-type(2)
    > div
    > div
    > div.MuiDataGrid-row
    > div.MuiDataGrid-cell:nth-of-type(6) {
    width: 134px !important;
    max-width: 134px !important;
    min-width: 134px !important;
  }

  /* username */

  .task-list-dashboard
    > div
    > div
    > .MuiDataGrid-columnHeadersInner
    > div
    > div.MuiDataGrid-columnHeader:nth-of-type(7) {
    width: 142px !important;
    max-width: 142px !important;
    min-width: 142px !important;
  }
  .task-list-dashboard
    > div
    > div:nth-of-type(2)
    > div
    > div
    > div.MuiDataGrid-row
    > div.MuiDataGrid-cell:nth-of-type(7) {
    width: 142px !important;
    max-width: 142px !important;
    min-width: 142px !important;
  }
}
