.reports-list > div {
  width: 1575px !important;
}

.reports-list {
  overflow-x: scroll !important;
  overflow-y: unset !important;
}

/* id */
.reports-list
  > div
  > div
  > .MuiDataGrid-columnHeadersInner
  > div
  > div.MuiDataGrid-columnHeader:nth-of-type(1) {
  width: 150px !important;
  max-width: 150px !important;
  min-width: 150px !important;
}
.reports-list
  > div
  > div:nth-of-type(2)
  > div
  > div
  > div.MuiDataGrid-row
  > div.MuiDataGrid-cell:nth-of-type(1) {
  width: 150px !important;
  max-width: 150px !important;
  min-width: 150px !important;
}

/* topic */
.reports-list
  > div
  > div
  > .MuiDataGrid-columnHeadersInner
  > div
  > div.MuiDataGrid-columnHeader:nth-of-type(2) {
  width: 200px !important;
  max-width: 200px !important;
  min-width: 200px !important;
}
.reports-list
  > div
  > div:nth-of-type(2)
  > div
  > div
  > div.MuiDataGrid-row
  > div.MuiDataGrid-cell:nth-of-type(2) {
  width: 200px !important;
  max-width: 200px !important;
  min-width: 200px !important;
}

/* subject */
.reports-list
  > div
  > div
  > .MuiDataGrid-columnHeadersInner
  > div
  > div.MuiDataGrid-columnHeader:nth-of-type(3) {
  width: 250px !important;
  max-width: 250px !important;
  min-width: 250px !important;
}
.reports-list
  > div
  > div:nth-of-type(2)
  > div
  > div
  > div.MuiDataGrid-row
  > div.MuiDataGrid-cell:nth-of-type(3) {
  width: 250px !important;
  max-width: 250px !important;
  min-width: 250px !important;
}

/* date recieved */
.reports-list
  > div
  > div
  > .MuiDataGrid-columnHeadersInner
  > div
  > div.MuiDataGrid-columnHeader:nth-of-type(4) {
  width: 150px !important;
  max-width: 150px !important;
  min-width: 150px !important;
}
.reports-list
  > div
  > div:nth-of-type(2)
  > div
  > div
  > div.MuiDataGrid-row
  > div.MuiDataGrid-cell:nth-of-type(4) {
  width: 150px !important;
  max-width: 150px !important;
  min-width: 150px !important;
}

/* severity */
.reports-list
  > div
  > div
  > .MuiDataGrid-columnHeadersInner
  > div
  > div.MuiDataGrid-columnHeader:nth-of-type(5) {
  width: 125px !important;
  max-width: 125px !important;
  min-width: 125px !important;
}
.reports-list
  > div
  > div:nth-of-type(2)
  > div
  > div
  > div.MuiDataGrid-row
  > div.MuiDataGrid-cell:nth-of-type(5) {
  width: 125px !important;
  max-width: 125px !important;
  min-width: 125px !important;
}

/* stage */
.reports-list
  > div
  > div
  > .MuiDataGrid-columnHeadersInner
  > div
  > div.MuiDataGrid-columnHeader:nth-of-type(6) {
  width: 230px !important;
  max-width: 230px !important;
  min-width: 230px !important;
}
.reports-list
  > div
  > div:nth-of-type(2)
  > div
  > div
  > div.MuiDataGrid-row
  > div.MuiDataGrid-cell:nth-of-type(6) {
  width: 230px !important;
  max-width: 230px !important;
  min-width: 230px !important;
}

/* next deadline */
.reports-list
  > div
  > div
  > .MuiDataGrid-columnHeadersInner
  > div
  > div.MuiDataGrid-columnHeader:nth-of-type(7) {
  width: 150px !important;
  max-width: 150px !important;
  min-width: 150px !important;
}
.reports-list
  > div
  > div:nth-of-type(2)
  > div
  > div
  > div.MuiDataGrid-row
  > div.MuiDataGrid-cell:nth-of-type(7) {
  width: 150px !important;
  max-width: 150px !important;
  min-width: 150px !important;
}

/* status */
.reports-list
  > div
  > div
  > .MuiDataGrid-columnHeadersInner
  > div
  > div.MuiDataGrid-columnHeader:nth-of-type(8) {
  width: 120px !important;
  max-width: 120px !important;
  min-width: 120px !important;
}
.reports-list
  > div
  > div:nth-of-type(2)
  > div
  > div
  > div.MuiDataGrid-row
  > div.MuiDataGrid-cell:nth-of-type(8) {
  width: 120px !important;
  max-width: 120px !important;
  min-width: 120px !important;
}

/* final deadline */
.reports-list
  > div
  > div
  > .MuiDataGrid-columnHeadersInner
  > div
  > div.MuiDataGrid-columnHeader:nth-of-type(9) {
  width: 200px !important;
  max-width: 200px !important;
  min-width: 200px !important;
}
.reports-list
  > div
  > div:nth-of-type(2)
  > div
  > div
  > div.MuiDataGrid-row
  > div.MuiDataGrid-cell:nth-of-type(9) {
  width: 200px !important;
  max-width: 200px !important;
  min-width: 200px !important;
}
