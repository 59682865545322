@media (max-width: 1560px) {
  .my-matters-list > div {
    width: 1160px !important;
  }
}

@media (min-width: 1561px) {
  .my-matters-list > div {
    width: 1690px !important;
  }
}

.my-matters-list {
  overflow-x: scroll !important;
  overflow-y: unset !important;
}

/* client */
.my-matters-list
  > div
  > div
  > .MuiDataGrid-columnHeadersInner
  > div
  > div.MuiDataGrid-columnHeader:nth-of-type(2) {
  width: 170px !important;
  max-width: 170px !important;
  min-width: 170px !important;
}
.my-matters-list
  > div
  > div:nth-of-type(2)
  > div
  > div
  > div.MuiDataGrid-row
  > div.MuiDataGrid-cell:nth-of-type(2) {
  width: 170px !important;
  max-width: 170px !important;
  min-width: 170px !important;
}

/* name */

.my-matters-list
  > div
  > div
  > .MuiDataGrid-columnHeadersInner
  > div
  > div.MuiDataGrid-columnHeader:nth-of-type(3) {
  width: 140px !important;
  max-width: 140px !important;
  min-width: 140px !important;
}
.my-matters-list
  > div
  > div:nth-of-type(2)
  > div
  > div
  > div.MuiDataGrid-row
  > div.MuiDataGrid-cell:nth-of-type(3) {
  width: 140px !important;
  max-width: 140px !important;
  min-width: 140px !important;
}

/* description */
@media (max-width: 1560px) {
  .my-matters-list
    > div
    > div
    > .MuiDataGrid-columnHeadersInner
    > div
    > div.MuiDataGrid-columnHeader:nth-of-type(4) {
    width: 300px !important;
    max-width: 300px !important;
    min-width: 300px !important;
  }
  .my-matters-list
    > div
    > div:nth-of-type(2)
    > div
    > div
    > div.MuiDataGrid-row
    > div.MuiDataGrid-cell:nth-of-type(4) {
    width: 300px !important;
    max-width: 300px !important;
    min-width: 300px !important;
  }
}

@media (min-width: 1561px) {
  .my-matters-list
    > div
    > div
    > .MuiDataGrid-columnHeadersInner
    > div
    > div.MuiDataGrid-columnHeader:nth-of-type(4) {
    width: 800px !important;
    max-width: 800px !important;
    min-width: 800px !important;
  }
  .my-matters-list
    > div
    > div:nth-of-type(2)
    > div
    > div
    > div.MuiDataGrid-row
    > div.MuiDataGrid-cell:nth-of-type(4) {
    width: 800px !important;
    max-width: 800px !important;
    min-width: 800px !important;
  }
}

/* severity */

.my-matters-list
  > div
  > div
  > .MuiDataGrid-columnHeadersInner
  > div
  > div.MuiDataGrid-columnHeader:nth-of-type(5) {
  width: 125px !important;
  max-width: 125px !important;
  min-width: 125px !important;
}
.my-matters-list
  > div
  > div:nth-of-type(2)
  > div
  > div
  > div.MuiDataGrid-row
  > div.MuiDataGrid-cell:nth-of-type(5) {
  width: 125px !important;
  max-width: 125px !important;
  min-width: 125px !important;
}

/* stage */

.my-matters-list
  > div
  > div
  > .MuiDataGrid-columnHeadersInner
  > div
  > div.MuiDataGrid-columnHeader:nth-of-type(6) {
  width: 230px !important;
  max-width: 230px !important;
  min-width: 230px !important;
}
.my-matters-list
  > div
  > div:nth-of-type(2)
  > div
  > div
  > div.MuiDataGrid-row
  > div.MuiDataGrid-cell:nth-of-type(6) {
  width: 230px !important;
  max-width: 230px !important;
  min-width: 230px !important;
}

/* deadline*/

.my-matters-list
  > div
  > div
  > .MuiDataGrid-columnHeadersInner
  > div
  > div.MuiDataGrid-columnHeader:nth-of-type(7) {
  width: 139px !important;
  max-width: 139px !important;
  min-width: 139px !important;
}
.my-matters-list
  > div
  > div:nth-of-type(2)
  > div
  > div
  > div.MuiDataGrid-row
  > div.MuiDataGrid-cell:nth-of-type(7) {
  width: 139px !important;
  max-width: 139px !important;
  min-width: 139px !important;
}
