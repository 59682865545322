.Collapsible__container {
  background: #fcfcfc;
  border: 1px solid #eee;
  border-radius: 10px;
  margin-bottom: 8px;
}

.Collapsible__title {
  cursor: pointer;
  padding: 5px 5px 5px 20px;
  position: relative;
  font-weight: bold;
  list-style: none;
  outline: none;
}

.Collapsible__title::marker,
.Collapsible__title::-webkit-details-marker {
  display: none;
}

.Collapsible__title:before {
  border-style: solid;
  border-color: transparent;
  border-width: 4px 6px 4px 6px;
  border-left-color: #000;
  display: block;
  content: '';
  position: absolute;
  left: 7px;
  top: 50%;
  transform: translateY(-50%);
}

.Collapsible__container[open] > .Collapsible__title:before {
  border-color: transparent;
  border-width: 6px 4px 0 4px;
  border-top-color: #000;
}

.Collapsible__content {
  padding: 0 5px 5px 20px;
}

.Collapsible__collapsed .Collapsible__content {
  display: none;
  user-select: none;
}
