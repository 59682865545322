.interviews-matter-list {
  overflow-x: scroll !important;
  overflow-y: unset !important;
}

@media (min-width: 1675px) {
  .interviews-matter-list > div {
    width: 1585px !important;
  }

  /* interviewee name */
  .interviews-matter-list
    > div
    > div
    > .MuiDataGrid-columnHeadersInner
    > div
    > div.MuiDataGrid-columnHeader:nth-of-type(2) {
    width: 405px !important;
    max-width: 405px !important;
    min-width: 405px !important;
  }
  .interviews-matter-list
    > div
    > div:nth-of-type(2)
    > div
    > div
    > div.MuiDataGrid-row
    > div.MuiDataGrid-cell:nth-of-type(2) {
    width: 405px !important;
    max-width: 405px !important;
    min-width: 405px !important;
  }
}

@media (max-width: 1674px) {
  .interviews-matter-list > div {
    width: 1375px !important;
  }

  /* interviewee name */
  .interviews-matter-list
    > div
    > div
    > .MuiDataGrid-columnHeadersInner
    > div
    > div.MuiDataGrid-columnHeader:nth-of-type(2) {
    width: 181px !important;
    max-width: 181px !important;
    min-width: 181px !important;
  }
  .interviews-matter-list
    > div
    > div:nth-of-type(2)
    > div
    > div
    > div.MuiDataGrid-row
    > div.MuiDataGrid-cell:nth-of-type(2) {
    width: 181px !important;
    max-width: 181px !important;
    min-width: 181px !important;
  }
}

/* category */
.interviews-matter-list
  > div
  > div
  > .MuiDataGrid-columnHeadersInner
  > div
  > div.MuiDataGrid-columnHeader:nth-of-type(3) {
  width: 125px !important;
  max-width: 125px !important;
  min-width: 125px !important;
}
.interviews-matter-list
  > div
  > div:nth-of-type(2)
  > div
  > div
  > div.MuiDataGrid-row
  > div.MuiDataGrid-cell:nth-of-type(3) {
  width: 125px !important;
  max-width: 125px !important;
  min-width: 125px !important;
}

/* type */
.interviews-matter-list
  > div
  > div
  > .MuiDataGrid-columnHeadersInner
  > div
  > div.MuiDataGrid-columnHeader:nth-of-type(4) {
  width: 103px !important;
  max-width: 103px !important;
  min-width: 103px !important;
}
.interviews-matter-list
  > div
  > div:nth-of-type(2)
  > div
  > div
  > div.MuiDataGrid-row
  > div.MuiDataGrid-cell:nth-of-type(4) {
  width: 103px !important;
  max-width: 103px !important;
  min-width: 103px !important;
}

/* date */
.interviews-matter-list
  > div
  > div
  > .MuiDataGrid-columnHeadersInner
  > div
  > div.MuiDataGrid-columnHeader:nth-of-type(5) {
  width: 263px !important;
  max-width: 263px !important;
  min-width: 263px !important;
}
.interviews-matter-list
  > div
  > div:nth-of-type(2)
  > div
  > div
  > div.MuiDataGrid-row
  > div.MuiDataGrid-cell:nth-of-type(5) {
  width: 263px !important;
  max-width: 263px !important;
  min-width: 263px !important;
}

/* duration */
.interviews-matter-list
  > div
  > div
  > .MuiDataGrid-columnHeadersInner
  > div
  > div.MuiDataGrid-columnHeader:nth-of-type(6) {
  width: 120px !important;
  max-width: 120px !important;
  min-width: 120px !important;
}
.interviews-matter-list
  > div
  > div:nth-of-type(2)
  > div
  > div
  > div.MuiDataGrid-row
  > div.MuiDataGrid-cell:nth-of-type(6) {
  width: 120px !important;
  max-width: 120px !important;
  min-width: 120px !important;
}

/* status */
.interviews-matter-list
  > div
  > div
  > .MuiDataGrid-columnHeadersInner
  > div
  > div.MuiDataGrid-columnHeader:nth-of-type(7) {
  width: 175px !important;
  max-width: 175px !important;
  min-width: 175px !important;
}
.interviews-matter-list
  > div
  > div:nth-of-type(2)
  > div
  > div
  > div.MuiDataGrid-row
  > div.MuiDataGrid-cell:nth-of-type(7) {
  width: 175px !important;
  max-width: 175px !important;
  min-width: 175px !important;
}

/* users */
.interviews-matter-list
  > div
  > div
  > .MuiDataGrid-columnHeadersInner
  > div
  > div.MuiDataGrid-columnHeader:nth-of-type(8) {
  width: 150px !important;
  max-width: 150px !important;
  min-width: 150px !important;
}
.interviews-matter-list
  > div
  > div:nth-of-type(2)
  > div
  > div
  > div.MuiDataGrid-row
  > div.MuiDataGrid-cell:nth-of-type(8) {
  width: 150px !important;
  max-width: 150px !important;
  min-width: 150px !important;
}

/* status */
.interviews-matter-list
  > div
  > div
  > .MuiDataGrid-columnHeadersInner
  > div
  > div.MuiDataGrid-columnHeader:nth-of-type(9) {
  width: 170px !important;
  max-width: 170px !important;
  min-width: 170px !important;
}
.interviews-matter-list
  > div
  > div:nth-of-type(2)
  > div
  > div
  > div.MuiDataGrid-row
  > div.MuiDataGrid-cell:nth-of-type(9) {
  width: 170px !important;
  max-width: 170px !important;
  min-width: 170px !important;
}
