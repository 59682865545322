@media (max-width: 2560px) {
  .MuiDataGrid-columnHeadersInner
    > div
    > div.MuiDataGrid-columnHeader:nth-of-type(6) {
    width: 230px !important;
  }
  .MuiDataGrid-row > div.MuiDataGrid-cell:nth-of-type(6) {
    width: 230px !important;
  }
}

@media (max-width: 1850px) {
  .matters-list-homeportal > div {
    width: 1887px !important;
  }
  .matters-list-homeportal {
    overflow-x: scroll !important;
  }
}

@media (min-width: 1850px) and (max-width: 2560px) {
  .table-mask-shadow {
    display: none !important;
  }
}
.matters-list-homeportal
  > div
  > div:nth-of-type(2)
  > div
  > div
  > div.MuiDataGrid-row
  > div.MuiDataGrid-cell:nth-of-type(3) {
  white-space: pre-wrap !important;
}

.matters-list-homeportal
  > div
  > div:nth-of-type(2)
  > div
  > div
  > div.MuiDataGrid-row
  > div.MuiDataGrid-cell:nth-last-child(-n + 3) {
  display: flex;
  justify-content: flex-end;
  padding-right: 2.2% !important;
}

.matters-list-homeportal
  > div
  > div
  > .MuiDataGrid-columnHeadersInner
  > div
  > .MuiDataGrid-columnHeader:nth-last-child(-n + 3)
  > div
  > div {
  justify-content: flex-end;
}

@media (max-width: 1440px) {
  .matters-list-homeportal > div {
    width: 1445px !important;
  }
  .matters-list-homeportal {
    overflow-x: scroll !important;
    overflow-y: unset !important;
  }

  /* client */
  .matters-list-homeportal
    > div
    > div
    > .MuiDataGrid-columnHeadersInner
    > div
    > div.MuiDataGrid-columnHeader:nth-of-type(1) {
    width: 170px !important;
    max-width: 170px !important;
    min-width: 170px !important;
  }
  .matters-list-homeportal
    > div
    > div:nth-of-type(2)
    > div
    > div
    > div.MuiDataGrid-row
    > div.MuiDataGrid-cell:nth-of-type(1) {
    width: 170px !important;
    max-width: 170px !important;
    min-width: 170px !important;
  }

  /* name */

  .matters-list-homeportal
    > div
    > div
    > .MuiDataGrid-columnHeadersInner
    > div
    > div.MuiDataGrid-columnHeader:nth-of-type(2) {
    width: 140px !important;
    max-width: 140px !important;
    min-width: 140px !important;
  }
  .matters-list-homeportal
    > div
    > div:nth-of-type(2)
    > div
    > div
    > div.MuiDataGrid-row
    > div.MuiDataGrid-cell:nth-of-type(2) {
    width: 140px !important;
    max-width: 140px !important;
    min-width: 140px !important;
  }

  /* description */

  .matters-list-homeportal
    > div
    > div
    > .MuiDataGrid-columnHeadersInner
    > div
    > div.MuiDataGrid-columnHeader:nth-of-type(3) {
    width: 300px !important;
    max-width: 300px !important;
    min-width: 300px !important;
  }
  .matters-list-homeportal
    > div
    > div:nth-of-type(2)
    > div
    > div
    > div.MuiDataGrid-row
    > div.MuiDataGrid-cell:nth-of-type(3) {
    width: 300px !important;
    max-width: 300px !important;
    min-width: 300px !important;
  }

  /* date */

  .matters-list-homeportal
    > div
    > div
    > .MuiDataGrid-columnHeadersInner
    > div
    > div.MuiDataGrid-columnHeader:nth-of-type(4) {
    width: 139px !important;
    max-width: 139px !important;
    min-width: 139px !important;
  }
  .matters-list-homeportal
    > div
    > div:nth-of-type(2)
    > div
    > div
    > div.MuiDataGrid-row
    > div.MuiDataGrid-cell:nth-of-type(4) {
    width: 139px !important;
    max-width: 139px !important;
    min-width: 139px !important;
  }

  /* status */

  .matters-list-homeportal
    > div
    > div
    > .MuiDataGrid-columnHeadersInner
    > div
    > div.MuiDataGrid-columnHeader:nth-of-type(5) {
    width: 165px !important;
    max-width: 165px !important;
    min-width: 165px !important;
  }
  .matters-list-homeportal
    > div
    > div:nth-of-type(2)
    > div
    > div
    > div.MuiDataGrid-row
    > div.MuiDataGrid-cell:nth-of-type(5) {
    width: 165px !important;
    max-width: 165px !important;
    min-width: 165px !important;
  }

  /* ongoing */

  .matters-list-homeportal
    > div
    > div
    > .MuiDataGrid-columnHeadersInner
    > div
    > div.MuiDataGrid-columnHeader:nth-of-type(6) {
    width: 165px !important;
    max-width: 165px !important;
    min-width: 165px !important;
  }
  .matters-list-homeportal
    > div
    > div:nth-of-type(2)
    > div
    > div
    > div.MuiDataGrid-row
    > div.MuiDataGrid-cell:nth-of-type(6) {
    width: 165px !important;
    max-width: 165px !important;
    min-width: 165px !important;
  }

  /* upcoming */

  .matters-list-homeportal
    > div
    > div
    > .MuiDataGrid-columnHeadersInner
    > div
    > div.MuiDataGrid-columnHeader:nth-of-type(7) {
    width: 206px !important;
    max-width: 206px !important;
    min-width: 206px !important;
  }
  .matters-list-homeportal
    > div
    > div:nth-of-type(2)
    > div
    > div
    > div.MuiDataGrid-row
    > div.MuiDataGrid-cell:nth-of-type(7) {
    width: 206px !important;
    max-width: 206px !important;
    min-width: 206px !important;
  }

  /* active */

  .matters-list-homeportal
    > div
    > div
    > .MuiDataGrid-columnHeadersInner
    > div
    > div.MuiDataGrid-columnHeader:nth-of-type(8) {
    width: 160px !important;
    max-width: 160px !important;
    min-width: 160px !important;
  }
  .matters-list-homeportal
    > div
    > div:nth-of-type(2)
    > div
    > div
    > div.MuiDataGrid-row
    > div.MuiDataGrid-cell:nth-of-type(8) {
    width: 160px !important;
    max-width: 160px !important;
    min-width: 160px !important;
  }
}
