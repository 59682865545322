/* when inserting new table change this games to selectors */

@media (max-width: 2560px) {
  .task-list > div {
    width: 1597px !important;
  }
}

@media (max-width: 1850px) {
  .task-list > div {
    width: 1520px !important;
  }
  .task-list {
    overflow-x: scroll !important;
  }
}

@media (min-width: 1850px) and (max-width: 2560px) {
  .table-mask-shadow {
    display: none !important;
  }
}

@media (max-width: 1440px) {
  .task-list > div {
    width: 1120px !important;
  }
  .task-list {
    overflow-x: scroll !important;
    overflow-y: unset !important;
  }

  /* task */
  .task-list
    > div
    > div
    > .MuiDataGrid-columnHeadersInner
    > div
    > div.MuiDataGrid-columnHeader:nth-of-type(2) {
    width: 350px !important;
    max-width: 350px !important;
    min-width: 350px !important;
  }
  .task-list
    > div
    > div:nth-of-type(2)
    > div
    > div
    > div.MuiDataGrid-row
    > div.MuiDataGrid-cell:nth-of-type(2) {
    width: 350px !important;
    max-width: 350px !important;
    min-width: 350px !important;
  }

  /* status */
  .task-list
    > div
    > div
    > .MuiDataGrid-columnHeadersInner
    > div
    > div.MuiDataGrid-columnHeader:nth-of-type(3) {
    display: none;
  }
  .task-list
    > div
    > div:nth-of-type(2)
    > div
    > div
    > div.MuiDataGrid-row
    > div.MuiDataGrid-cell:nth-of-type(3) {
    display: none;
  }

  /* status */
  .task-list
    > div
    > div
    > .MuiDataGrid-columnHeadersInner
    > div
    > div.MuiDataGrid-columnHeader:nth-of-type(4) {
    width: 125px !important;
    max-width: 125px !important;
    min-width: 125px !important;
  }
  .task-list
    > div
    > div:nth-of-type(2)
    > div
    > div
    > div.MuiDataGrid-row
    > div.MuiDataGrid-cell:nth-of-type(4) {
    width: 125px !important;
    max-width: 125px !important;
    min-width: 125px !important;
  }

  /* deadline */

  .task-list
    > div
    > div
    > .MuiDataGrid-columnHeadersInner
    > div
    > div.MuiDataGrid-columnHeader:nth-of-type(5) {
    width: 140px !important;
    max-width: 140px !important;
    min-width: 140px !important;
  }
  .task-list
    > div
    > div:nth-of-type(2)
    > div
    > div
    > div.MuiDataGrid-row
    > div.MuiDataGrid-cell:nth-of-type(5) {
    width: 140px !important;
    max-width: 140px !important;
    min-width: 140px !important;
  }

  /* first name */

  .task-list
    > div
    > div
    > .MuiDataGrid-columnHeadersInner
    > div
    > div.MuiDataGrid-columnHeader:nth-of-type(6) {
    width: 150px !important;
    max-width: 150px !important;
    min-width: 150px !important;
  }
  .task-list
    > div
    > div:nth-of-type(2)
    > div
    > div
    > div.MuiDataGrid-row
    > div.MuiDataGrid-cell:nth-of-type(6) {
    width: 150px !important;
    max-width: 150px !important;
    min-width: 150px !important;
  }

  /* last name */

  .task-list
    > div
    > div
    > .MuiDataGrid-columnHeadersInner
    > div
    > div.MuiDataGrid-columnHeader:nth-of-type(7) {
    width: 150px !important;
    max-width: 150px !important;
    min-width: 150px !important;
  }
  .task-list
    > div
    > div:nth-of-type(2)
    > div
    > div
    > div.MuiDataGrid-row
    > div.MuiDataGrid-cell:nth-of-type(7) {
    width: 150px !important;
    max-width: 150px !important;
    min-width: 150px !important;
  }

  /* username */

  .task-list
    > div
    > div
    > .MuiDataGrid-columnHeadersInner
    > div
    > div.MuiDataGrid-columnHeader:nth-of-type(8) {
    width: 150px !important;
    max-width: 150px !important;
    min-width: 150px !important;
  }
  .task-list
    > div
    > div:nth-of-type(2)
    > div
    > div
    > div.MuiDataGrid-row
    > div.MuiDataGrid-cell:nth-of-type(8) {
    width: 150px !important;
    max-width: 150px !important;
    min-width: 150px !important;
  }
}
